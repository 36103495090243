<template>
  <span>
    <template v-if="dayVisible">{{ days }}</template>
    {{ hours }}{{ minutes }}{{ seconds }}
  </span>
</template>
<script>
export default {
  props: {
    date: {
      type: String,
      default: '2022-01-01 00:00:00'
    },
    dayVisible: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      now: Math.trunc(new Date().getTime() / 1000)
    }
  },
  computed: {
    dateInMilliseconds () {
      return Math.trunc(Date.parse(this.date) / 1000)
    },
    seconds () {
      return ((this.dateInMilliseconds - this.now) % 60)
        .toString()
        .padStart(2, '0')
    },
    minutes () {
      return (
        (Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60)
          .toString()
          .padStart(2, '0') + ':'
      )
    },
    hours () {
      return (
        (Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24)
          .toString()
          .padStart(2, '0') + ':'
      )
    },
    days () {
      return (
        Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24)
          .toString()
          .padStart(2, '0') + ':'
      )
    }
  },
  mounted () {
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000)
    }, 1000)
  }
}
</script>
