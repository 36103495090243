<template>
<div>
    <header class="page-header">
        <el-row :gutter="20" align="middle">
            <el-col :span="12">
            <h2> Групп захиалга</h2>
            </el-col>
        </el-row>
    </header>
    <div class="panel tabled">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="20">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="search" @change="onSearch">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                </el-col>
              </el-row>
            </div>
          </div>
        <el-tabs v-model="activeTabStatus" @tab-click="onClickTab">
            <el-tab-pane :label="tab.label" :name="tab.value" v-for="(tab, indexTab) in tabData" :key="indexTab">
              <el-table
                v-loading="isLoading"
                :data="groupOrders"
                style="width: 100%">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-table :data="props.row.Orders" :row-class-name="tableRowClassName">
                      <el-table-column label="Захиалгын дугаар" prop="order_number"></el-table-column>
                      <el-table-column label="Статус" prop="order_status"></el-table-column>
                      <el-table-column label="Жолооч" prop="driver_name"></el-table-column>
                      <el-table-column label="Хүргэх огноо" prop="promised_time"></el-table-column>
                      <el-table-column label="Төлбөр" prop="pay_status"></el-table-column>
                      <el-table-column label="Нийт төлөх" prop="pay_total"></el-table-column>
                      <el-table-column label="Утас" prop="user_phone"></el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column
                  label="ID"
                  prop="id"
                  width="80px">
                </el-table-column>
                <el-table-column
                  label="Нэр"
                  prop="group_order_name"
                  width="160px">
                </el-table-column>
                <el-table-column
                  label="Утас"
                  prop="creator_phone"
                  width="120px">
                </el-table-column>
                <el-table-column
                  label="Үүсгэсэн огноо"
                  prop="createdAt"
                  width="160px">
                </el-table-column>
                <el-table-column
                  label="Дуусах огноо"
                  width="160px">
                  <template slot-scope="scope">
                    <div v-if="scope.row.order_status === 'Баталгаажсан'">
                      <countDown
                        :date="scope.row.expired_at"
                        :day-visible="false"
                        ></countDown>
                    </div>
                    <div v-else>
                      {{scope.row.expired_at}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Бүрдэх тоо"
                  prop="order_count"
                  width="100px">
                </el-table-column>
                <el-table-column
                  label="Нийт"
                  prop="Orders.length"
                  width="80px">
                </el-table-column>
                <el-table-column
                  label="Төлөөгүй"
                  width="100px">
                  <template slot-scope="scope">
                    {{canceledOrderCount(scope.row.Orders)}}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Захиалгын төлөв"
                  width="135px">
                  <template slot-scope="scope">
                  <el-tag effect="dark" :type="scope.row.order_status === 'Цуцалсан' ? 'danger' : scope.row.order_status === 'Хадгалсан' ? 'warning'
                   : scope.row.order_status === 'Амжилттай' ? 'success' : scope.row.order_status === 'Хэрэглэгчийн тоо бүрдсэн' ? 'info' : ''">{{ scope.row.order_status }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="Цуцлах" width="130px" v-if="activeTabStatus !== 'paid' && activeTabStatus !== 'Not paid'">
                  <template slot-scope="scope">
                  <el-button type="success" :disabled="scope.row.order_status === 'Амжилттай' || scope.row.order_status === 'Цуцалсан'"
                    @click="showGroupOrderCancelModal(scope.row.id, scope.row)">Цуцлах</el-button>
                  </template>
                </el-table-column>
                <el-table-column label="Дэлгэрэнгүй"
                width="150px">
                  <template slot-scope="scope">
                  <el-button type="success"
                    @click="showGroupOrderDetailModal(scope.row)">Дэлгэрэнгүй</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-pagination
              class="text-right mt10"
              background
              :page-size="pageSize"
              :current-page.sync="currentPage"
              :page-sizes="[5, 20, 50, 100]"
              layout="sizes, prev, pager, next"
              @size-change="sizeChangeHandler"
              @current-change="curentPageHandler"
              :total="totalCount">
            </el-pagination>
        </el-tabs>
        <el-dialog
          title="Хамтдаа захиалга цуцлах"
          :visible.sync="groupOrderCancel"
          width="50%">
          <el-form ref="groupOrderCancelForm" v-if="groupOrderCancelForm.groupOrder" :model="groupOrderCancelForm" :rules="cancelGroupOrderFormRules">
            <el-form-item label="Хамтдаа захиалгын дугаар">
              <el-input v-model="groupOrderCancelForm.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="Хамтдаа захиалгын нэр">
              <el-input v-model="groupOrderCancelForm.groupOrder.group_order_name" disabled></el-input>
            </el-form-item>
            <el-form-item label="Хамтдаа захиалгын төлөв">
              <el-input v-model="groupOrderCancelForm.groupOrder.order_status" disabled></el-input>
            </el-form-item>
            <el-form-item label="Хамтдаа захиалгын төлөв">
              <el-input v-model="groupOrderCancelForm.groupOrder.order_status" disabled></el-input>
            </el-form-item>
            <el-form-item label="Цуцлах шалтгаан"  prop="reason">
              <el-select v-model="groupOrderCancelForm.reason" placeholder="Шалтгаанаа сонгох">
                <el-option
                  v-for="item in cancelReasons"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Тайлбар" prop="desc">
              <el-input type="textarea" v-model="groupOrderCancelForm.desc"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="hideFormGroupOrder">Cancel</el-button>
            <el-button type="primary" @click="groupOrderCancelF" :disabled="cancelGroupOrderButtonDisable">Confirm</el-button>
          </span>
        </el-dialog>
        <el-dialog
          title="Хамтдаа захиалгын дэлгэрэнгүй"
          :visible.sync="groupOrderDetail"
          width="50%">
          <div style="display:grid; grid-template-columns: repeat(4, 1fr); grid-gap:1rem">
          <el-tag type="danger" style="font-weight:bold"><span style="color:black"> ID: </span>{{groupOrdersData.id}}</el-tag>
          <el-tag type="danger" style="font-weight:bold"><span style="color:black"> Нэр: </span>{{groupOrdersData.group_order_name}}</el-tag>
          <el-tag type="danger" style="font-weight:bold"><span style="color:black"> Хүний тоо: </span>{{groupOrdersData.order_count}}</el-tag>
          <el-tag type="danger" style="font-weight:bold"><span style="color:black"> Захиалгын төлөв: </span>{{groupOrdersData.order_status}}</el-tag>
          <el-tag type="danger" style="font-weight:bold"><span style="color:black"> Эхлэх огноо: </span>{{groupOrdersData.createdAt}}</el-tag>
          <el-tag type="danger" style="font-weight:bold"><span style="color:black"> Дуусах огноо: </span>{{groupOrdersData.expired_at}}</el-tag>
          <el-tag type="danger" style="font-weight:bold"><span style="color:black"> Утасны дугаар: </span>{{groupOrdersData.creator_phone}}</el-tag>
          </div>
          <el-table
            :data="groupOrderDetailData"
            height="250"
            style="width: 100%">
            <el-table-column
              prop="order_number"
              label="Захиалгын дугаар"
              width="150">
            </el-table-column>
            <el-table-column
              label="Брэнд нэр"
              width="150">
              <template slot-scope="scope">
                {{scope.row.OrderItems[0].brand_name}}
              </template>
            </el-table-column>
            <el-table-column
              label="Бүтээгдэхүүн"
              width="200">
              <template slot-scope="scope">
                {{scope.row.OrderItems[0].product_name}}
              </template>
            </el-table-column>
            <el-table-column
              label="Ширхэг"
              width="85">
              <template slot-scope="scope">
                {{parseFloat(scope.row.OrderItems[0].item_count)}}
              </template>
            </el-table-column>
            <el-table-column
              prop="promised_time"
              label="Хүргэх хугацаа"
              width="150">
            </el-table-column>
            <el-table-column
              prop="user_phone"
              label="Утасны дугаар" width="150">
            </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="groupOrderDetailCancel">Cancel</el-button>
          </span>
        </el-dialog>
    </div>
</div>
</template>

<script>
import countDown from '../../../components/common/countdown.vue'
import services from '../../../helpers/services'
export default {
  created: function () {
    // this.getOrderGroup()
  },
  components: {
    countDown
  },
  data () {
    return {
      cancelReasons: [
        {
          value: 'Хэрэглэгч цуцаляаа гэсэн',
          label: 'Хэрэглэгч цуцаляаа гэсэн'
        }, {
          value: 'Тест',
          label: 'Тест'
        }, {
          value: 'Хүргэж өгөхөө болисон',
          label: 'Хүргэж өгөхөө болисон'
        }, {
          value: 'Үлдэгдэл хүрэлцээгүй',
          label: 'Үлдэгдэл хүрэлцээгүй'
        }, {
          value: 'Захиалсан хүн нь тэнэгтээд байсан',
          label: 'Захиалсан хүн нь тэнэгтээд байсан'
        }
      ],
      groupOrderCancelForm: {
        reason: null,
        desc: null,
        groupOrder: null,
        id: null
      },
      groupOrdersData: [],
      groupOrderDetailData: [],
      groupOrderDetail: false,
      groupOrderCancel: false,
      activeTabStatus: 'all',
      search: '',
      cancelGroupOrderFormRules: {
        reason: [
          { required: true, message: 'Please input Activity name', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: 'Please select Activity zone', trigger: 'change' }
        ]
      },
      cancelGroupOrderButtonDisable: false,
      from: '',
      order_status: null,
      groupOrders: [],
      totalCount: 0,
      pageSize: 20,
      pageFrom: 0,
      currentPage: 1,
      isLoading: false,
      tabData: [
        {
          label: 'Бүгд',
          value: 'all'
        }, {
          label: 'Хадгалсан',
          value: 'save'
        }, {
          label: 'Хүний тоо бүрдсэн',
          value: 'full'
        }, {
          label: 'Баталгаажсан',
          value: 'confirmed'
        }, {
          label: 'Амжилттай',
          value: 'paid'
        }, {
          label: 'Цуцалсан захиалга',
          value: 'Not paid'
        }
      ]
    }
  },
  mounted () {
    this.getGroupOrders()
  },
  methods: {
    groupOrderDetailCancel () {
      this.groupOrderDetail = false
      this.groupOrderDetailData = []
    },
    showGroupOrderDetailModal (data) {
      this.groupOrderDetailData = data.Orders
      this.groupOrdersData = data
      this.groupOrderDetail = true
    },
    getGroupOrders () {
      var from = (this.pageSize * (this.currentPage - 1))
      var size = this.pageSize
      if (this.$route.query.page && this.$route.query.size) {
        const incomingPage = this.$route.query.page ? parseFloat(this.$route.query.page) : 0
        const incomingSize = parseFloat(this.$route.query.size)
        this.currentPage = incomingPage
        this.pageSize = incomingSize
        from = (this.pageSize * (incomingPage - 1))
        size = incomingSize
      }
      this.from = from
      this.size = size
      this.getOrderGroup(from, size, this.search)
    },
    hideFormGroupOrder () {
      this.groupOrderCancel = false
      this.groupOrderCancelForm = {
        reason: null,
        desc: null,
        groupOrder: null,
        id: null
      }
    },
    groupOrderCancelF () {
      this.cancelGroupOrderButtonDisable = true
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$refs.groupOrderCancelForm.validate((valid) => {
        if (valid) {
          services.cancelGroupOrder(this.groupOrderCancelForm, this.groupOrderCancelForm.id).then(response => {
            loading.close()
            if (response.status === 'success') {
              this.$notify({
                title: 'Амжилттай',
                message: response.message,
                type: 'success'
              })
              this.cancelGroupOrderButtonDisable = false
              this.hideFormGroupOrder()
              this.getGroupOrders()
            } else {
              this.$notify({
                title: 'Амжилтгүй',
                message: response.message,
                type: 'success'
              })
              this.cancelGroupOrderButtonDisable = false
            }
          })
        } else {
          loading.close()
          this.cancelGroupOrderButtonDisable = false
          return false
        }
      })
    },
    showGroupOrderCancelModal (groupOrderId, groupOrder) {
      this.groupOrderCancelForm.id = groupOrderId
      this.groupOrderCancelForm.groupOrder = groupOrder
      this.groupOrderCancel = true
    },
    canceledOrderCount (orders) {
      let count = 0
      orders.forEach(order => {
        if (order.pay_status === 'Төлөөгүй') {
          count++
        }
      })
      return count
    },
    tableRowClassName ({ row }) {
      if (row.pay_status === 'Төлөөгүй') {
        return 'warning-row'
      } else if (row.pay_status === 'Төлсөн') {
        return 'success-row'
      }
    },
    groupOrderTableRowClassName ({ row }) {
      if (row.order_status === 'Хадгалсан') {
        return 'danger-row'
      } else if (row.order_status === 'Амжилттай') {
        return 'success-row'
      } else {
        return 'warning-row'
      }
    },
    onSearch () {
      this.getOrderGroup(this.from, this.size)
    },
    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'orderGroup', query: { page: this.currentPage, size: this.pageSize, search: this.search } })
      this.getOrderGroup(this.pageSize * (this.currentPage - 1), this.pageSize, this.search)
    },
    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'orderGroup', query: { page: this.currentPage, size: this.pageSize, search: this.search } })
      this.getOrderGroup(this.pageSize * (this.currentPage - 1), this.pageSize, this.search)
    },
    getOrderGroup (from, size) {
      const query = '?order_status=' + this.order_status + '&search_text=' + this.search + '&from=' + from + '&size=' + size
      this.isLoading = true
      services.getOrderGroup(query).then(response => {
        if (response.status === 'success') {
          this.groupOrders = response.groupOrders
          this.totalCount = response.total
        }
        this.isLoading = false
      })
    },
    onClickTab (data) {
      this.currentPage = 1
      if (data.label === 'Цуцалсан захиалга') {
        this.order_status = 91
      } else if (data.label === 'Амжилттай') {
        this.order_status = 100
      } else if (data.label === 'Баталгаажсан') {
        this.order_status = 50
      } else if (data.label === 'Бүгд') {
        this.order_status = null
      } else if (data.label === 'Хүний тоо бүрдсэн') {
        this.order_status = 60
      } else if (data.label === 'Хадгалсан') {
        this.order_status = 10
      }
      this.$router.push({ name: 'orderGroup', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabStatus } })
      this.getOrderGroup(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabStatus === 'all' ? '' : this.activeTabStatus)
    }
    // notify (title, message, type) {
    //   this.$notify({
    //     title: title,
    //     message: message,
    //     type: type,
    //     position: 'bottom-left'
    //   })
    //   this.isLoading = false
    // }
  }
}
</script>
<style>
  .el-table .warning-row {
    background: oldlace;
  }
  .el-table .danger-row {
    background: #ffa1a1;
  }
  .el-table .success-row {
    background: #8aff7bc7;
  }
</style>
